:root {
    --white: #fff;
    --grey-e: #eee;
    --grey-d: #ddd;
    --grey-c: #ccc;
    --grey-b: #bbb;
    --grey-9: #999;
    --grey-7: #777;
    --grey-5: #555;
    --grey-4: #444;
    --grey-3: #333;
    --grey-2: #222;
    --black: #111;

    --white: #fff;
    --red: #ae2033;
    --pale-pink: #ffebe8;
    --pale-yellow: #ffebe8;
    --brick: #793b1d;
    --cream: #ffe7d3;
    --biro: #2f4858;
    --off-white: #dfe0df;
    --green-dark: #2b520b;

    --heading: #554062;
    --text: var(--biro);
    --heading-alt: var(--white);
    --text-alt: var(--white);
    --theme-primary: #5f8c3b;
    --theme-alt: #dfc26b;
    --theme-alt-dark: #c9ad5a;
    --bg: #f4f4f4;
    --danger: var(--red);
    --danger-bg: var(--pale-pink);
    --warning: #F27E4E;
    --success: #00ce72;

    --note-yellow: #ffe040;
    --note-pink: #edafbc;
    --note-blue: #b2cde8;
    --note-green: #d1e6dd;

    --highlighter: rgba(251, 247, 60, 0.5);
}
// $social-media-colors: (
//     // facebook: #4365a5,
//     facebook: #1877f2,
//     instagram: #dc2c74,
//     twitter: #31b0dd
// );

