@import '../styles/globals';

.note {
    --postcard-footer-width: 14rem;
    /* order: 1; */
    display: grid;
    grid-template-areas: "note-header" "note-image" "note-message" "note-footer";

    &.is-postcard {
        grid-template-areas:
        "note-header note-header"
        "note-message note-footer"
        ;
    }

}

.note-header {
    grid-area: note-header;
}

.note-image {
    grid-area: note-image;
}

.note-message {
    grid-area: note-message;
}

.note-footer {
    grid-area: note-footer;
}

.note {
    background-color: var(--white);
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.05) 95%
    );
    background-blend-mode: multiply;
    border-radius: var(--radius-lg);
    box-shadow: var(--box-shadow);
    color: var(--text);
    margin: 0 auto 0 0;

    padding: 0 var(--space) var(--space);
    position: relative;
    // overflow: hidden; pin gets cut off
    text-decoration: none;
    transition: all 0.3s ease;
    z-index: 1;


    @include breakpoint(medium) {
        min-width: 18rem;
        min-height: 19rem;
        // max-width: 50vw;
        // max-height: 50vh;
    }

    // &.note {
    //     &:hover,
    //     &:focus {
    //         text-decoration: none;
    //         transform: scale(1.06);
    //     }
    // }


    &.is-stickynote {
        background-image: linear-gradient(
            0deg,
            rgba(18, 18, 18, 0) 0%,
            rgba(18, 18, 18, 0.1) 90%,
            rgba(18, 18, 18, 0.05) 91%,
            rgba(18, 18, 18, 0.15) 98%
        );
    }

    &.is-postcard {
        background: {
            image: url("../assets/images/postcard-bk3.svg");
            position: top var(--space-lg) right var(--space-lg);
            repeat: no-repeat;
            size: calc(var(--postcard-footer-width) + var(--space-lg));
        }
        border-radius: 0;
    }

    &[data-background] {
        background-color: attr(data-background);
    }

    a {
        color: currentColor;
        text-decoration: none;
    }

    h4 {
        color: var(--theme-primary);
    }

    p {
        color: var(--text);
        font-family: var(--font-hand);
        font-weight: 400;
    }

}

.note-header {
    --icon-size: 1rem;
    background: transparent;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    position: relative;
    min-height: var(--icon-size);
    position: relative;
    z-index: 15;
}

.note-pin {
    display: block;
    height: 2rem;
    margin: 0 auto;
    position: absolute;
    top: -0.5rem;
    right: 0;
    left: 0;
    filter: sepia(1) hue-rotate(160deg) saturate(3);
    z-index: 15;

    // .is-stickynote & {
    //     left: auto;
    //     right: -1.5rem;
    // }
}

.note-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 0 var(--space);
    position: relative;
    overflow: hidden;
    z-index: 10;
    max-height: 50vh;

    .is-postcard & {
        --m: calc(var(--space) * -1);
        --w: calc(var(--space) * 2 + 100%);
        grid-column: 1/-1;
        grid-row: 1/-1;

        margin: 0 var(--m) var(--m);
        transition: opacity var(--transition);
        transition-delay: var(--transition-speed);
        height: 0;
        padding-top: 60%;
        // // min-width: var(--w);
        // min-width: 0;
        // max-width: var(--w);
        // max-height: 100%;
        // height: auto;
        // width: auto;
        position: relative;
        min-height: 19rem;
        max-height: 50vh;

        &:hover,
        &:focus {
            opacity: 0;
        }
    }

    img {
        display: block;
        min-width: 100%;
        max-width: 100%;
        margin: auto;

        .is-postcard & {
            position: absolute;
            opacity: 0;
            max-height: 100%;
        }
    }
}

.note-footer {
    display: flex;
    font-size: var(--p-sm);

    align-items: center;
    margin-top: auto;

    .is-postcard & {
        flex-wrap: wrap;
        font-size: var(--p);
        margin: 0 0 auto auto;
        width: var(--postcard-footer-width);
    }

    h6 {
        font-size: var(--p-sm);
        margin: 0;
        padding: 0;

        .is-postcard & {
            font-size: var(--p);
            margin-bottom: var(--space);
            width: 100%;

            &:after {
                content: ':';
            }
        }
    }

    .avatar {
        --size: 2rem;
        height: var(--size);
        width: var(--size);
        margin: 0 var(--space-xs);

        .is-postcard & {
            display: none;
        }
    }

    p {
        margin: 0;
        padding: 0;
    }
}

.note-message {
    h4 {
        color: var(--font-heading);
        font-family: var(--font-hand);
        font-size: var(--h4);
        margin: 0 0 var(--space);
    }

    p {
        font-size: var(--p);
        margin: 0 0 var(--space-sm);
    }

    a {
        font-weight: 700;
    }

    .is-postcard & {
        max-width: 50%;
    }
}

/* .list-avatars {
    display: flex;
        flex-wrap: wrap;
}

.list-avatars li {
    margin: 0 var(--space-sm) var(--space-sm) 0;
}

.list-avatars .avatar {
    display: block;
        width: 1.875rem;
        height: 1.875rem;
} */


.stamp-postmark {
    --stamp-height: calc(var(--postcard-footer-width) / 2);
    position: absolute;
        top: var(--space-lg);
        right: var(--space-lg);
    width: var(--postcard-footer-width);
    height: var(--stamp-height);

    .avatar {
        position: absolute;
        top: 0;
        right: 0;
        margin: auto 0;
        border-radius: 0;
        height: calc(var(--stamp-height) * 0.9);
        width: calc(var(--stamp-height) * 0.56);
        transform: translateY(0.2rem) translateX(-0.14rem);
    }

    .stamp-frame {
        position: absolute;
        top: 0;
        right: 0;
        width:calc(var(--stamp-height) * 1.7);
        height:var(--stamp-height);
        z-index: 1;
    }
}

