.avatar {
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    display: inline-block;
        height: 3rem;
        width: 3rem;
    overflow: hidden;
    position: relative;

    img {
        opacity: 0;
        position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        max-width: 100%;
    }
}