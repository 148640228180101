// z-index
:root {
    --zi-base: 10;
    --zi-header: 30;
    --zi-header-nav: calc(30 + 1);
    --zi-hamburger: calc(30 + 10);
    --zi-popup: calc(30 + 20);
    --zi-blocker: calc(30 + 50)
}

// Shorthand
$hover-states: '&:hover, &:active, &:focus';
$hover-hf: '&:hover, &:focus';

// Layout widths, add more as required
// classname : width
// $layout-widths: (
//     col-15: 15%,
//     col-20: 20%,
//     col-25: 25%,
//     col-30: 30%,
//     col-33: calc(100 * 1/3 * 1%),
//     col-40: 40%,
//     col-50: 50%,
//     col-60: 60%,
//     col-66:  calc(100 * 2/3 * 1%),
//     col-70: 70%,
//     col-75: 75%,
//     col-80: 80%,
//     col-100: 100%
// );
