@import '../styles/globals';

.list-notes {
    display: grid;
        // /* grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); */
        grid-template-columns: repeat(2, 1fr);
        /* grid-template-columns: repeat(auto-fill, minmax(180px, 260px)); */
        grid-auto-flow: dense;
        grid-gap: var(--space-md);
        justify-content: start;
        align-items: start;

    @include breakpoint(large) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include breakpoint(xl) {
        grid-template-columns: repeat(6, 1fr);
    }

    >li {
        grid-area: span 1;
    }
}

.list-notes .stickynote {
}

.list-notes .postcard {
    grid-area:  span 2 / span 2;
    width: 2fr;

    @include breakpoint(large) {
        grid-area: span 2 / span 3;
    }
}

.list-notes .polaroid {
    @include breakpoint(large) {
        grid-area: span 2 / span 2;
    }
}

.list-notes li:nth-child(2n) {
    transform: rotate(-0.5deg);
    /* outline: 2px solid purple; */
}

.list-notes li:nth-child(2n+4) {
    transform: translateY(-0.3rem);
    /* outline: 2px solid pink; */
}


.list-notes li:nth-child(3n) {
    transform: rotate(1.5deg);
    /* outline: 2px solid red; */
}

.list-notes li:nth-child(3n+6) {
    transform: rotate(0.5deg);
    /* outline: 2px solid orange; */
}

.list-notes li:nth-child(5n) {
    transform: rotate(-1.5deg);
    /* outline: 2px solid lime; */
}

.list-notes li:nth-child(2n) .note-pin {
    filter: sepia(1) hue-rotate(200deg) saturate(5);
    transform: translateX(-0.5rem) translateY(0.5rem);
        /* outline: 2px solid blue; */
}

.list-notes li:nth-child(2n+4) .note-pin {
    filter: sepia(1) hue-rotate(90deg) saturate(3);

    transform: translateX(-0.5rem) translateY(0.5rem);
    /* outline: 2px solid aqua; */
}


.list-notes li:nth-child(3n) .note-pin {
    transform: translateX(-0.5rem);
    /* outline: 2px solid red; */
}

.list-notes li:nth-child(3n+6) .note-pin {
    transform: translateY(0.25rem);
    /* outline: 2px solid orange; */
}

.list-notes li:nth-child(5n) .note-pin {
    filter: sepia(1) hue-rotate(230deg) saturate(3);
    transform: rotate(-1.5deg);
    /* outline: 2px solid lime; */
}

/* .list-notes .note[data-saved="true"] {
    order: 0;
} */

