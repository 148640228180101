.list-filters {
    display: flex;

    list-style: none;
    margin: 0;
    padding: 0;

    label {
        color: var(--text-alt);
        cursor: pointer;
        display: flex;
            align-items: center;
        font-size: var(--p-sm);
        font-weight: 400;
        padding-right: var(--space)
    }

    input {
        margin: 0 var(--space-xs) 0 0;
        padding: 0;
    }
}
