.btn {
    margin-bottom: var(--space);

    &.is-white {
        border-color: var(--white);
        color: var(--white);

        &:hover,
        &:focus {
            background-color: var(--white);
            border-color: var(--white);
            color: var(--text);
        }
    }

    &.is-text {
        border: 0 none;
        color: currentColor;

        &:hover,
        &:focus {
            background-color: transparent;
            border-color: transparent;
        }
    }

    &.is-underlined {
        border: 0 none;
        border-bottom: 1px solid;
        border-radius: 0;
        color: currentColor;
        font-family: inherit;
        font-size: inherit;
        font-weight: normal;
        margin: 0;
        padding: 0;
    }
}
