.list-reactions {
    display: flex;
        flex-direction: column;
    list-style: none;
    margin-left: auto;
    position: absolute;
        top: var(--space-sm);
        right: 0;

        &:hover,
        &:focus {
            li {
                max-height: 10rem;
            }
            img {
                filter: grayscale(0.5);
                opacity: 1;
                transform: scale(0.9);
            }
        }

    li {
        order: 1;
        margin: 0;
        padding: 0;
        max-height: 0;
        transition: all var(--transition);
    }

    [data-active=true] {
        order: 0;
        max-height: 10rem;

        img {
            filter: grayscale(0);
            opacity: 0.85;
            transform: scale(1);
        }
    }

    .is-reaction {
        margin: 0 0 var(--space-sm);
        padding: 0;
        text-align: right;


        &:hover,
        &:focus {
            img {
                filter: grayscale(0);
                transform: scale(1);
            }
        }
    }

    input {
        &:checked {
            ~ img {
                filter: grayscale(0);
                opacity: 0.85;
                transform: scale(1);
            }
        }
    }

    img {
        display: inline-block;
            max-height: 1.6rem;
            max-width: 2.6rem;
        opacity: 0;
        filter: grayscale(0.85);
        transform: scale(0.8);
        transform-origin: center;
    }
}

